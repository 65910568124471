<template>
  <div v-show="!showModal">
    <a-spin :spinning="loading">
      <a-form ref="form" :model="formState" name="form" @finish="onSearch">
        <!-- 搜索栏 -->
        <a-row>
          <a-form-item label="活动名称" name="title" class="ui-form__item">
            <a-input v-model:value="formState.title" placeholder="请输入活动名称"></a-input>
          </a-form-item>

          <a-form-item class="ui-form__item" name="isDisabled" label="状态">
            <a-select v-model:value="formState.isDisabled" placeholder="请选择状态" allow-clear style="width: 180px;">
              <a-select-option :value="0">启用</a-select-option>
              <a-select-option :value="1">禁用</a-select-option>
            </a-select>
          </a-form-item>

          <a-form-item class="ui-form__item" label="创建时间" name="createTime">
            <a-range-picker v-model:value="formState.createTime"/>
          </a-form-item>
        </a-row>

        <a-row>
          <a-col :span="18">
            <a-button v-permission="['coupon_preferential_Recharge_add']" type="primary" @click="onAdd">新增充值活动</a-button>
          </a-col>
          <a-col :span="6" style="text-align: right">
            <a-button
                style="margin-right: 10px"
                type="primary"
                html-type="submit"
            >搜索</a-button>
            <a-button @click="reset">重置</a-button>
          </a-col>
        </a-row>
      </a-form>

      <div style="margin-top: 20px;">
        <a-table :pagination="pagination" :columns="columns" :dataSource="list" rowKey="id"
                 :scroll="{ x: 950 }">
          <template #bodyCell="{ column, record }">
            <template v-if="column.key === 'effectiveType'">
              <div v-if="record.effectiveType">长期有效</div>
              <div v-else>{{ transDateTime(record.startTime, 1) }} --- {{ transDateTime(record.endTime, 1) }}</div>
            </template>
            <template v-if="column.key === 'createTime'">
              {{ transDateTime(record.createTime) }}
            </template>
            <template v-if="column.key === 'isDisabled'">
              <a-tag v-if="!record.isDisabled" color="#87d068">启用</a-tag>
              <a-tag v-if="record.isDisabled" color="#f50">禁用</a-tag>
            </template>
            <template v-if="column.key === 'action'">
              <a-dropdown :trigger="['click', 'hover']">
                <a-button @click.prevent>操作 <Icon icon="DownOutlined"></Icon>
                </a-button>
                <template #overlay>
                  <a-menu>
                    <div v-permission="['coupon_preferential_Recharge_edit']" @click="onEdit(record)">
                      <a-menu-item>
                        编辑
                      </a-menu-item>
                    </div>
                    <div v-permission="['coupon_preferential_Recharge_open']" v-if="record.isDisabled" @click="onDisabled(record)">
                      <a-menu-item>
                        启用
                      </a-menu-item>
                    </div>
                    <div v-permission="['coupon_preferential_Recharge_close']" v-else @click="onDisabled(record)">
                      <a-menu-item>
                        禁用
                      </a-menu-item>
                    </div>
                    <div v-permission="['coupon_preferential_Recharge_delete']" @click="onDelete(record)">
                      <a-menu-item>
                        删除
                      </a-menu-item>
                    </div>
                  </a-menu>
                </template>
              </a-dropdown>
            </template>
          </template>
        </a-table>
      </div>
    </a-spin>

  </div>
  <temp v-if="showModal" :isEdit="isEdit" :isSee="isSee" :id="id" @back="onBack"></temp>
</template>

<script>
import temp from './Recharge.vue'
import {
  Icon
} from '@/components/icon/icon.js';
import {
  deleteUnionCardChargeActivity,
  getUnionCardChargeActivityList,
  updateUnionCardChargeActivity
} from "../../../../service/modules/coupon";
export default {
  components: {
    Icon,
    temp
  },
  data() {
    return {
      showModal: false,
      isEdit: false,
      isSee: false,
      id: 0,
      loading: false,
      showAll: false,
      searchData: {},
      formState: {},
      columns: [{
        title: '充值活动名称',
        dataIndex: 'title',
      }, {
        title: '充值金额',
        dataIndex: 'chargePrice',
      }, {
        title: '赠送金额',
        dataIndex: 'accountPrice',
      }, {
        title: '有效期',
        key: 'effectiveType',
      }, {
        title: '创建人',
        dataIndex: 'createUserName',
      }, {
        title: '创建时间',
        key: 'createTime',
      }, {
        title: '状态',
        key: 'isDisabled',
      }, {
        title: '操作',
        key: 'action',
        fixed: 'right',
        width: 150
      }],
      list: [],
      pagination: {
        showSizeChanger: true,
        showQuickJumper: true,
        size: "middle",
        pageSizeOptions: ['10','20', '50', '100', '500'],
        showTotal: (total) => {
          return "共 " + total + " 条";
        },
        total: 0,
        current: 1,
        pageSize: 10,
        onChange: (page) => {
          this.pagination.current = page;
          this.getData();
        },
        onShowSizeChange: (page, pageSize) => {
          this.pagination.current = 1;
          this.pagination.pageSize = pageSize;
          this.getData();
        }
      },
    }
  },
  created() {
    this.getData();
  },
  methods: {
    reset() {
      this.$refs.form.resetFields();
      this.onSearch();
    },
    onSearch() {
      this.pagination.current = 1;
      this.searchData = JSON.parse(JSON.stringify(this.formState));
      this.getData();
    },
    onAdd() {
      this.showModal = true;
      this.isEdit = false;
    },
    onEdit(item) {
      this.id = item.id;
      this.showModal = true;
      this.isEdit = true;
    },
    onBack(isRef) {
      this.showModal = false;
      if (isRef) {
        this.$nextTick(() => {
          this.getData();
        })
      }
    },
    async getData() {
      if(this.formState.createTime && this.formState.createTime.length === 2) {
        this.searchData.createStartTime = this.moment(this.formState.createTime[0].startOf('day')).unix();
        this.searchData.createEndTime = this.moment(this.formState.createTime[1].endOf('day')).unix();
      }
      this.loading = true;
      try {
        let ret = await getUnionCardChargeActivityList({
          page: this.pagination.current,
          pageSize: this.pagination.pageSize,
          ...this.searchData
        })
        this.loading = false;
        if(ret.code === 200) {
          this.list = ret.data.list;
          this.pagination.total = ret.data.totalCount;
        }
      } catch(e) {
        this.loading = false;
      }
    },
    onDisabled(item) {
      this.$confirm({
        title: '提示',
        content: '确认' + (item.isDisabled ? '启用' : '禁用') + '该充值活动吗？',
        onOk: async () => {
          this.loading = true;
          try {
            let ret = await updateUnionCardChargeActivity({
              id: item.id,
              isDisabled: item.isDisabled ? 0 : 1
            })
            this.loading = false;
            if(ret.code === 200) {
              this.$message.success('操作成功');
              this.getData();
            }
          } catch(e) {
            this.loading = false;
          }
        }
      })
    },
    onDelete(item) {
      this.$confirm({
        title: '提示',
        content: '确认要删除该活动吗',
        onOk: async () => {
          this.loading = true;
          try {
            let ret = await deleteUnionCardChargeActivity({
              id: item.id
            });
            this.loading = false;
            if(ret.code === 200) {
              this.$message.success('操作成功');
              this.getData();
            }
          } catch(e) {
            this.loading = false;
          }
        }
      })
    },
  }
}
</script>

<style scoped>
.ui-form__item {
  margin-right: 30px;
}
</style>
