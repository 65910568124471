<template>
  <div class="detail">
    <Header :title="isEdit ? '修改充值活动' : '新增充值活动'" @back="onBack(false)"></Header>
    <a-spin :spinning="loading">
      <a-form ref="form" :model="modelRef" style="margin-top: 30px;" name="form"
              :labelCol="{span: 4 }"
              :wrapperCol="{span: 18 }"
      >
        <a-form-item label="充值活动名称" name="title" :rules="[{required: true, message: '必填项不允许为空'}]">
          <a-input style="width: 200px" v-model:value="modelRef.title" placeholder="请输入充值活动名称"></a-input>
        </a-form-item>

        <a-form-item label="充值金额" name="chargePrice" :rules="[{required: true, message: '必填项不允许为空'}]">
          <a-input style="width: 200px" v-model:value="modelRef.chargePrice" placeholder="请输入充值金额"></a-input>
          <span class="ui-min">元</span>
        </a-form-item>

        <a-form-item label="赠送金额" name="accountPrice" extra="提示：不填则不赠送">
          <a-input style="width: 200px" v-model:value="modelRef.accountPrice" placeholder="请输入赠送金额"></a-input>
          <span class="ui-min">元</span>
        </a-form-item>

        <a-form-item label="是否长期有效" name="effectiveType">
          <a-switch v-model:checked="modelRef.effectiveType" />
        </a-form-item>
        <a-form-item v-if="!modelRef.effectiveType" label="活动有效期" name="effectiveTime" :rules="[{required: true, message: '必填项不允许为空'}]">
          <a-range-picker v-model:value="modelRef.effectiveTime"></a-range-picker>
        </a-form-item>

        <a-form-item label="是否含有券" name="sendCouponStatus">
          <a-switch v-model:checked="modelRef.sendCouponStatus" />
        </a-form-item>

        <a-form-item label="赠送券" v-if="modelRef.sendCouponStatus" :rules="[{required: true, message: '必填项不允许为空'}]">
          <a-form-item-rest>
            <selectCoupon v-model:value="couponList" :selectItems="list" @change="(dataList) => {list = dataList}"></selectCoupon>
          </a-form-item-rest>
          <div v-if="modelRef.sendCouponStatus && list.length" style="margin-top: 20px; width: 600px;">
            <a-table :pagination="false" :columns="columns" :dataSource="list" rowKey="id"
                     :scroll="{ x: 500 }">
              <template #bodyCell="{ column, record, index }">
                <template v-if="column.key === 'type'">
                  <div v-if="record.type === 1">代金券</div>
                  <div v-if="record.type === 2">兑换券</div>
                </template>
                <template v-if="column.key === 'action'">
                  <a-button type="link" @click="onDelete(record, index)">移除</a-button>
                </template>
              </template>
            </a-table>
          </div>
        </a-form-item>

        <a-form-item style="margin-top: 30px;" label="是否启用" name="isDisabled">
          <a-switch v-model:checked="modelRef.isDisabled" checked-children="启用" un-checked-children="禁用" />
        </a-form-item>

        <a-form-item style="text-align: center;margin-top: 20px;">
          <a-button type="primary" @click="onSubmit">提交</a-button>
        </a-form-item>
      </a-form>
    </a-spin>
  </div>
</template>

<script>
import Header from '@/components/header/header.vue';
import selectCoupon from "@/components/select/selectCoupon/index.vue";
import { getUnionCardChargeActivityDetail, saveUnionCardChargeActivity, updateUnionCardChargeActivity } from "@/service/modules/coupon";
export default {
  components: {
    selectCoupon,
    Header
  },
  props: {
    isEdit: {
      type: Boolean,
      default: false
    },
    id: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      loading: false,
      couponList: [],
      modelRef: {

      },
      columns: [{
        title: '券名称',
        dataIndex: 'title',
      }, {
        title: '券类型',
        key: 'type',
      }, {
        title: '操作',
        key: 'action',
      }],
      list: [],
    };
  },
  created() {
    if(this.isEdit) {
      this.getData();
    }
  },
  methods: {
    onBack(isRef) {
      this.$emit('back', isRef);
    },
    async getData() {
      this.loading = true;
      try {
        let ret = await getUnionCardChargeActivityDetail({
          id: this.id
        });
        this.loading = false;
        if(ret.code === 200) {
          this.list = ret.data.couponList;
          ret.data.effectiveType = ret.data.effectiveType ? true : false;
          ret.data.sendCouponStatus = ret.data.sendCouponStatus ? true : false;
          ret.data.isDisabled = ret.data.isDisabled ? false : true;
          if (ret.data.startTime) {
            ret.data.startTime = this.moment(ret.data.startTime * 1000);
          } else {
            ret.data.startTime = undefined;
          }
          if (ret.data.endTime) {
            ret.data.endTime = this.moment(ret.data.endTime * 1000);
          } else {
            ret.data.endTime = undefined;
          }
          if(ret.data.sendCouponList) {
            this.couponList = ret.data.sendCouponList.split(',');
          } else {
            this.couponList = [];
          }
          this.list = ret.data.sendAppCouponList;

          this.modelRef = ret.data;

          if(ret.data.startTime && ret.data.endTime) {
            const timeList = [];
            timeList.push(ret.data.startTime);
            timeList.push(ret.data.endTime);
            this.modelRef.effectiveTime = timeList;
          }
        }
      } catch(e) {
        this.loading = false;
      }
    },
    onDelete(item, index) {
      let i = this.couponList.indexOf(item.id);
      this.couponList.splice(i, 1);
      this.list.splice(index, 1);
    },
    async onSubmit() {
      this.$refs.form.validateFields().then(async () => {
        if(this.modelRef.sendCouponStatus && this.couponList.length ===0) {
          this.$message.error('请选择优惠券');
          return;
        }
        if(this.modelRef.effectiveTime && this.modelRef.effectiveTime.length === 2) {
          this.modelRef.startTime = this.moment(this.modelRef.effectiveTime[0].startOf('day')).unix();
          this.modelRef.endTime = this.moment(this.modelRef.effectiveTime[1].endOf('day')).unix();
        }
        if(this.couponList) {
          this.modelRef.sendCouponList = this.couponList.join(',');
        }
        this.modelRef.effectiveType = this.modelRef.effectiveType ? 1 : 0;
        this.modelRef.sendCouponStatus = this.modelRef.sendCouponStatus ? 1 : 0;
        this.modelRef.isDisabled = this.modelRef.isDisabled ? 0 : 1;
        let postData = JSON.parse(JSON.stringify(this.modelRef));
        this.loading = true;
        try {
          let ret;
          if(this.isEdit) {
            postData.id = this.id;
            ret = await updateUnionCardChargeActivity(postData);
          } else {
            ret = await saveUnionCardChargeActivity(postData);
          }
          this.loading = false;
          if(ret.code === 200) {
            this.$message.success('操作成功');
            this.onBack(true);
          }
        } catch(e) {
          this.loading = false;
        }
      })
    },
  }
};
</script>

<style lang="less" scoped>
.ui-min {
  display: inline-block;
  width: 40px;
  height: 31px;
  text-align: center;
  line-height: 31px;
  background-color: #d7d7d7;
  border-bottom-right-radius: 10px;
  border-top-right-radius: 10px;
}
</style>